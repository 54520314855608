<template>
  <a-pagination
    v-model="currentPage"
    :total="total"
    :page-size-options="pageSizeOptions"
    :page-size="pageSize"
    :show-title="false"
    show-less-items
    show-size-changer
    class="table-pagination"
    @change="customPageChange"
    @showSizeChange="onShowSizeChange"
  >
    <template slot="buildOptionText" slot-scope="props">
      <span>{{ props.value }} строк</span>
    </template>
  </a-pagination>
</template>

<script>
import APagination from 'ant-design-vue/lib/pagination';

export default {
  name: 'Pagination',
  components: {
    APagination
  },
  props: {
    total: Number,
    pageChanged: {
      type: Function,
    },
    perPageChanged: {
      type: Function,
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '20', '50', '100'],
    }
  },
  methods: {
    customPageChange(customCurrentPage) {
      this.pageChanged({currentPage: customCurrentPage});
    },
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.perPageChanged({currentPerPage: pageSize});
    }
  }
}

</script>

<style lang="scss">
.table-pagination {
  padding: 31px 20px 48px 38px;

  .ant-pagination-item {
    margin: 0;
    border-color: #D5D9E1;
    border-radius: 0;

    .ant-pagination-item-link {
      border-radius: 0;
    }

    &-active {
      color: #0059A3;
      background: rgba(0, 89, 163, 0.1);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #D5D9E1;
    }
  }

  .ant-pagination-prev {
    a {
      border-radius: 4px 0 0 4px;
    }
  }

  .ant-pagination-next {
    a {
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 27px;
  }
}
</style>