<template>
  <div class="modal">
    <div class="modal__overlay" aria-hidden="true"></div>
    <div class="modal__wrapper">
      <div class="modal__header">
        <h3>
          <slot name="title"></slot>
        </h3>
        <button @click="close"
                class="modal__header-close"
        >
          <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L1 10" stroke="#B1BACB" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M1 1L10 10" stroke="#B1BACB" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="modal__body">
        <div class="modal__form">
          <slot name="body"></slot>
        </div>
      </div>
      <div class="modal__footer">
        <div class="modal__footer-wrapper">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(113, 113, 122, 0.75);
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  &__wrapper {
    position: relative;
    padding: 40px 0 48px 0;
    background-color: #FFFFFF;
    border-radius: 8px;

    > div {
      padding: 0 48px;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 32px;
    padding-bottom: 26px;

    h3 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__header-close {
    margin-left: auto;
  }

  &__body {
    padding: 0 48px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-row {
    display: flex;
    flex-wrap: nowrap;

    &:last-of-type {
      margin-bottom: 32px;
    }
  }

  &__form-col {
    width: 100%;
    padding: 0 6px;

    &:nth-of-type(1) {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    input {
      width: 100%;
    }
  }

  &__form-text {
    margin-bottom: 32px;
    font-size: 16px;
  }

  .phone-input {
    width: auto;
    height: auto;
    padding: 15px 24px;
    border: 1px solid #D5D9E1;
    border-radius: 4px;
    font-size: 15px;
    line-height: 18px;
    color: #1D293F;

    &::placeholder {
      color: #1D293F;
    }

    &:focus {
      outline: none;
      border-color: #0059A3;
    }

  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}
</style>