import _ from 'lodash';

export const vueTable = {
    data: function () {
        return {
            create: false,
            view: false,
            edit: false,
            del: false,

            row: null,
            isLoading: false,
            rows: [],
            totalRecords: 0,
            url: null,
            serverParams: {
                columnFilters: {},
                sort: {
                    field: 'id',
                    type: 'desc',
                },
                page: 1,
                perPage: 10
            },

            paginationOptions: {
                enabled: true,
            }
        }
    },
    mounted() {
        this.loadItems();
        this.translate();
        this.inputDate();
    },
    methods: {
        inputDate() {
            let items = this.$refs['vueTable'].$refs['table-header-primary'].$refs['filter-row'].$el.children;

            if (items) {
                for(let item of items) {
                    if (
                        item.children[0] &&
                        item.children[0].children[0]
                    ) {
                        if (item.children[0].children[0].placeholder === 'Дата') {
                            item.children[0].children[0].type = 'date';
                        }

                        if (item.children[0].children[0].placeholder === 'ID') {
                            item.children[0].children[0].type = 'number';
                        }
                    }
                }
            }
        },

        translate() {
            this.$refs.vueTable.nextText = 'Следующая';
            this.$refs.vueTable.prevText = 'Предыдущая';
            this.$refs.vueTable.rowsPerPageText = 'Строк на странице';
            this.$refs.vueTable.ofText = 'из';
            this.$refs.vueTable.pageText = 'страница';
            this.$refs.vueTable.allText = 'Все';
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage, page: 1});
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({sort: params[0], page: 1});
            this.loadItems();
        },

        onColumnFilter(params) {
            for (let key in params.columnFilters) {
                if (key === 'documents') {
                    params.columnFilters.passport = params.columnFilters[key];
                }
                if (key === 'id') {
                    params.columnFilters.id = params.columnFilters[key].replace(/[^0-9]/g, '');
                }
            }
            this.updateParams({...params, page: 1});
            this.loadItems();
        },

        loadItems() {
            const serverParams = _.cloneDeep(this.serverParams);

            if (serverParams.columnFilters.phone) {
                serverParams.columnFilters.phone = _.replace(serverParams.columnFilters.phone, '+7', '');
            }

            this.getAll({params: serverParams})
                .then(response => {
                    this.totalRecords = response.data.meta.total;
                    this.rows = response.data.data;
                });
        },

        onView(row) {
            this.row = row;
            this.view = true;
        },

        onEdit(row) {
            this.row = row;
            this.edit = true;
        },

        onDelete(row) {
            this.row = row;
            this.del = true;
        },
    }
}
